import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from './plugins/vuetify'
import axios from 'axios'
import checkUrl from "./checkUrl";
import dateConverter from "./dateConverter";
import Vue2Editor from "vue2-editor";

Vue.config.productionTip = false
require('@/store/subscriber')
Vue.mixin(checkUrl);
Vue.mixin(dateConverter);
Vue.use(Vue2Editor);

axios.defaults.baseURL = "https://api.trendatiq.com/api/";
store.dispatch('auth/attempt', localStorage.getItem('token')).then(() => {
  new Vue({
    router,
    store,
    vuetify,
    render: h => h(App)
  }).$mount('#app')
})
