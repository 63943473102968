import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

export default new Vuetify({

    theme: {
        themes: {
            light: {
                primary: "#41E194",
                primary_light: "#BDFFE0",
                primary_dark: "#41E194",
                secondary: "#03595A", // #FFCDD2
                secondary_dark: "#004546", // #FFCDD2
                background: "#F1EFEF", // #FFCDD2
                background_blue: "#FAFCFC", // #FFCDD2
                pink: "#E2659F", // #FFCDD2
                pink_dark: "#713350", // #FFCDD2
                purple: "#737ED3", // #FFCDD2
                purple_dark: "#3A3F6A", // #FFCDD2
                textfield: "#E7E7E7", // #FFCDD2
                textfieldlight: "#EFF0F2", // #FFCDD2
                greytext: "#78797A", // #FFCDD2
                google: "#B31B1B", // #FFCDD2
                google_light: "#EA4335", // #FFCDD2
                facebook: "#4267B2", // #FFCDD2
                facebook_light: "#1877F2", // #FFCDD2
                chip:"#F1F1F1",
                red:"#FF0000",
                grey_blue:"#8094AE",
                primaryfont:"#364A63",
                reddish:"#D51B1B",
                active:"#1EE0AC",
            },
        },
    },

});
