import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store";
Vue.use(VueRouter);
const routes = [
  {
    path: "/",
    name: "login",
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/Login.vue"),
    beforeEnter: (to, from, next) => {
      if (store.getters["auth/authenticated"]) {
        return next({
          name: "Dashboard"
        });
      }
      next();
    }
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: () =>
      import(/* webpackChunkName: "add" */ "../views/Dashboard.vue"),
    beforeEnter: (to, from, next) => {
      if (!store.getters["auth/authenticated"]) {
        return next({
          name: "login"
        });
      }
      next();
    }
  },
  {
    path: "/users",
    name: "Users",
    component: () =>
      import(/* webpackChunkName: "users" */ "../views/Users.vue"),
    beforeEnter: (to, from, next) => {
      if (!store.getters["auth/authenticated"]) {
        return next({
          name: "login"
        });
      }
      next();
    }
  },
  {
    path: "/admins",
    name: "Admins",
    component: () =>
      import(/* webpackChunkName: "users" */ "../views/Admins.vue"),
    beforeEnter: (to, from, next) => {
      if (!store.getters["auth/authenticated"]) {
        return next({
          name: "login"
        });
      }
      next();
    }
  },
  {
    path: "/joined",
    name: "JoinedUsers",
    component: () =>
      import(/* webpackChunkName: "agents" */ "../views/JoinedUsers.vue"),
    beforeEnter: (to, from, next) => {
      if (!store.getters["auth/authenticated"]) {
        return next({
          name: "login"
        });
      }
      next();
    }
  },
  {
    path: "/winners",
    name: "winnerUsers",
    component: () =>
      import(/* webpackChunkName: "add" */ "../views/winnerUsers.vue"),
    beforeEnter: (to, from, next) => {
      if (!store.getters["auth/authenticated"]) {
        return next({
          name: "login"
        });
      }
      next();
    }
  },
  {
    path: "/cashback",
    name: "CashbackUsers",
    component: () =>
      import(/* webpackChunkName: "rs" */ "../views/CashbackUsers.vue"),
    beforeEnter: (to, from, next) => {
      if (!store.getters["auth/authenticated"]) {
        return next({
          name: "login"
        });
      }
      next();
    }
  },
  {
    path: "/payment-methods",
    name: "PaymentMethods",
    component: () =>
      import(/* webpackChunkName: "edit" */ "../views/PaymentMethods.vue"),
    beforeEnter: (to, from, next) => {
      if (!store.getters["auth/authenticated"]) {
        return next({
          name: "login"
        });
      }
      next();
    }
  },
  {
    path: "/settings",
    name: "Settings",
    component: () =>
      import(/* webpackChunkName: "add" */ "../views/Settings.vue"),
    beforeEnter: (to, from, next) => {
      if (!store.getters["auth/authenticated"]) {
        return next({
          name: "login"
        });
      }
      next();
    }
  },
  {
    path: "/cities",
    name: "Cities",
    component: () =>
      import(/* webpackChunkName: "cities" */ "../views/Cities.vue"),
    beforeEnter: (to, from, next) => {
      if (!store.getters["auth/authenticated"]) {
        return next({
          name: "login"
        });
      }
      next();
    }
  },

  {
    path: "/feedback",
    name: "Feedback",
    component: () =>
      import(/* webpackChunkName: "add" */ "../views/Feedback.vue"),
    beforeEnter: (to, from, next) => {
      if (!store.getters["auth/authenticated"]) {
        return next({
          name: "login"
        });
      }
      next();
    }
  },
  {
    path: "/notifications",
    name: "Notifications",
    component: () =>
      import(/* webpackChunkName: "add" */ "../views/Notifications.vue"),
    beforeEnter: (to, from, next) => {
      if (!store.getters["auth/authenticated"]) {
        return next({
          name: "login"
        });
      }
      next();
    }
  },
  {
    path: "/cms",
    name: "Cms",
    component: () =>
      import(/* webpackChunkName: "add" */ "../views/Dashboard.vue"),
    beforeEnter: (to, from, next) => {
      if (!store.getters["auth/authenticated"]) {
        return next({
          name: "login"
        });
      }
      next();
    }
  },
  {
    path: "/cms/about",
    name: "About",
    component: () =>
      import(/* webpackChunkName: "add" */ "../views/CMS/About.vue"),
    beforeEnter: (to, from, next) => {
      if (!store.getters["auth/authenticated"]) {
        return next({
          name: "login"
        });
      }
      next();
    }
  },
  {
    path: "/cms/privacy-policy",
    name: "PrivacyPolicy",
    component: () =>
      import(/* webpackChunkName: "add" */ "../views/CMS/PrivacyPolicy.vue"),
    beforeEnter: (to, from, next) => {
      if (!store.getters["auth/authenticated"]) {
        return next({
          name: "login"
        });
      }
      next();
    }
  },
  {
    path: "/profile",
    name: "Profile",
    component: () =>
      import(/* webpackChunkName: "profile" */ "../views/Profile/Profile.vue"),
    beforeEnter: (to, from, next) => {
      if (!store.getters["auth/authenticated"]) {
        return next({
          name: "login"
        });
      }
      next();
    }
  },
  {
    path: "/profile/change-password",
    name: "Profile",
    component: () =>
      import(/* webpackChunkName: "profile" */ "../views/Profile/ChangePassword.vue"),
    beforeEnter: (to, from, next) => {
      if (!store.getters["auth/authenticated"]) {
        return next({
          name: "login"
        });
      }
      next();
    }
  },
  {
    path: "/profile/change-email",
    name: "Profile",
    component: () =>
      import(/* webpackChunkName: "profile" */ "../views/Profile/ChangeEmail.vue"),
    beforeEnter: (to, from, next) => {
      if (!store.getters["auth/authenticated"]) {
        return next({
          name: "login"
        });
      }
      next();
    }
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
