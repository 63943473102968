<template>
  <v-app>
    <NavigationBar v-if="this.authenticated " />
    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
import NavigationBar from "./components/NavigationBar";
import { mapGetters } from "vuex";

export default {
  name: "App",
  components: {
    // navbar,
    NavigationBar,
  },

  data: () => ({
    //
  }),
  computed: {
    ...mapGetters({
      authenticated: "auth/authenticated",
    }),
  },
};
</script>
<style>

#app {
  background-color: #F1EFEF;
  direction: rtl;
}

</style>
