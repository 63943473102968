import axios from 'axios'

export default {
  namespaced: true,
  state: {
    token: null,
    user: null,
  },
  getters: {
    authenticated(state) {
      return state.token && state.user
    },
    user(state) {
      return state.user
    }
  },
  mutations: {
    SET_TOKEN(state, token) {
      state.token = token
    },
    SET_USER(state, data) {
      state.user = data
    }
  },
  actions: {
    async signIn({ dispatch }, credentials) {
      let response = await axios.post('admin/login', credentials)
      console.log(response)
      return dispatch('attempt', response.data.data.access_token)
    },
    async attempt({ commit, state }, token) {
      if (token) {
        commit('SET_TOKEN', token)
      }
      if (!state.token) {
        return
      }
      try {
        const response = await axios.get("admin/user")
        commit('SET_USER', response.data.data)
        return { ar_message: "تم تسجيل الدخول", en_message: "Login Successful", loggedIn: true, user: response.data.data }
      } catch (e) {
        commit('SET_TOKEN', null)
        commit('SET_USER', null)
      }
    },
    async Logout({ commit }) {
      await axios.get('admin/logout')
      commit('SET_TOKEN', null)
      commit('SET_USER', null)

    }
  },
}
