<template>
  <div>
    <v-navigation-drawer v-model="drawer" app class="white" right>
      <v-sheet height="64" color="primary" class="d-flex flex-row justify-center align-center">
        <div class="d-flex flex-row justify-center align-center mx-0">
          <v-avatar size="35">
            <img
              :src="
                isValidHttpUrl(user.avatar)
                  ? user.avatar
                  : constants.STORAGE_URL + user.avatar
              "
              alt="John"
            />
          </v-avatar>
          <p class="ma-0 mx-md-4 mx-2 white--text text-caption">
            {{ user.full_name }}
            <v-menu :close-on-content-click="false" offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon color="white" v-bind="attrs" v-on="on">
                  <v-icon>mdi-triangle-small-down</v-icon>
                </v-btn>
              </template>

              <v-card width="275" min-width="200">
                <v-row justify="center" no-gutters>
                  <v-col
                    cols="12"
                    class="d-flex justify-center align-center background px-3"
                  >
                    <v-list-item two-line>
                      <v-list-item-avatar>
                        <v-img
                          v-if="user.avatar"
                          :src="
                            isValidHttpUrl(user.avatar)
                              ? user.avatar
                              : constants.STORAGE_URL + user.avatar
                          "
                        >
                        </v-img>
                        <p v-else class="ma-0">No Image</p>
                      </v-list-item-avatar>

                      <v-list-item-content>
                        <p
                          class="
                            ma-0
                            text-body-2
                            font-weight-bold
                            primary--text
                          "
                        >
                          {{ user.name }}
                        </p>
                        <p class="ma-0 text-caption primaryfont--text">
                          {{ user.email }}
                        </p>
                      </v-list-item-content>
                    </v-list-item>
                  </v-col>
                  <v-col cols="12">
                    <v-divider></v-divider>
                  </v-col>
                  <v-col cols="12" class="px-3">
                    <v-list dense nav>
                      <v-btn
                        depressed
                        plain
                        v-for="(item, i) in items"
                        :key="i"
                        @click="$router.push(item.link)"
                      >
                        <v-icon small v-text="item.icon"></v-icon>
                        <p class="ml-4 ma-0">{{ item.text }}</p>
                      </v-btn>
                    </v-list>
                  </v-col>
                  <v-col cols="12">
                    <v-divider></v-divider>
                  </v-col>
                  <v-col cols="12" class="px-3">
                    <v-list dense nav>
                      <v-list-item link @click="Logout">
                        <v-list-item-icon>
                          <v-icon>mdi-logout</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                          <v-list-item-title>تسجيل الخروج</v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                  </v-col>
                </v-row>
              </v-card>
            </v-menu>
          </p>
        </div>
      </v-sheet>
      <v-divider></v-divider>
      <v-list dense nav>
        <div
          class="pt-4 px-2"
          v-for="[text, link, icon, isGroup, items] in links"
          :key="text"
        >
          <v-list-item
            v-if="!isGroup"
            link
            :to="link"
            color="white"
            active-class="primary"
          >
            <v-list-item-icon>
              <v-icon
                class="ml-2"
                :color="currentRouteName.includes(link) ? 'white' : 'grey_blue'"
                v-text="icon"
              ></v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title
                :class="{
                  'white--text': currentRouteName.includes(link),
                  'grey_blue--text': !currentRouteName.includes(link),
                }"
                >{{ text }}</v-list-item-title
              >
            </v-list-item-content>
          </v-list-item>
          <v-list-group v-if="isGroup" :prepend-icon="icon" no-action>
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title v-text="text"></v-list-item-title>
              </v-list-item-content>
            </template>
            <v-list-item
              v-for="[text, link] in items"
              :key="link"
              :to="link"
              color="white"
              active-class="primary"
            >
              <v-list-item-content>
                <v-list-item-title
                  :class="{
                    'white--text': currentRouteName == link,
                    'grey_blue--text': currentRouteName != link,
                  }"
                  v-text="text"
                ></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>
        </div>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar app outlined color="primary" flat dark height="64"> </v-app-bar>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import { mapActions } from "vuex";
import constants from "@/constants.js";

export default {
  data: () => ({
    drawer: null,
    constants: constants,
    selectedItem: 1,
    items: [
      { text: "حسابي", icon: "mdi-account-outline", link: "/profile" },
      {
        text: "تغيير الرمز السري",
        icon: "mdi-lock",
        link: "/profile/change-password",
      },
      {
        text: "تغيير الايميل",
        icon: "mdi-email",
        link: "/profile/change-email",
      },
    ],
    links: [
      ["الواجهة الرئيسية", "/dashboard", "mdi-view-dashboard", false, []],
      [
        "المستخدمين",
        "/users",
        "mdi-account-supervisor-circle-outline",
        false,
        [],
      ],
      ["المسؤولين", "/admins", "mdi-account-supervisor", false, []],
      ["مشتركين القاصة", "/joined", "mdi-database", false, []],
      ["فائزين Cashback", "/cashback", "mdi-refresh", false, []],
      ["فائزين السحبة", "/winners", "mdi-cash", false, []],
      [
        "بطاقات استلام الاموال",
        "/payment-methods",
        "mdi-credit-card",
        false,
        [],
      ],
      ["الاعدادات", "/settings", "mdi-cog-outline", false, []],
      ["المحافظات", "/cities", "mdi-city", false, []],
      ["رأي الزبائن", "/feedback", "mdi-comment-quote", false, []],
      ["الاشعارات", "/notifications", "mdi-bell", false, []],
    ],
  }),
  methods: {
    ...mapActions({
      LogoutAction: "auth/Logout",
    }),
    Logout() {
      this.LogoutAction().then(() => {
        this.$router.replace({ name: "login" });
      });
    },
  },
  computed: {
    currentRouteName() {
      return this.$route.path;
    },
    ...mapGetters({
      authenticated: "auth/authenticated",
      user: "auth/user",
    }),
  },
};
</script>
<style scoped>
.hover:hover {
  cursor: pointer;
}
</style>
