var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-navigation-drawer',{staticClass:"white",attrs:{"app":"","right":""},model:{value:(_vm.drawer),callback:function ($$v) {_vm.drawer=$$v},expression:"drawer"}},[_c('v-sheet',{staticClass:"d-flex flex-row justify-center align-center",attrs:{"height":"64","color":"primary"}},[_c('div',{staticClass:"d-flex flex-row justify-center align-center mx-0"},[_c('v-avatar',{attrs:{"size":"35"}},[_c('img',{attrs:{"src":_vm.isValidHttpUrl(_vm.user.avatar)
                ? _vm.user.avatar
                : _vm.constants.STORAGE_URL + _vm.user.avatar,"alt":"John"}})]),_c('p',{staticClass:"ma-0 mx-md-4 mx-2 white--text text-caption"},[_vm._v(" "+_vm._s(_vm.user.full_name)+" "),_c('v-menu',{attrs:{"close-on-content-click":false,"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"white"}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-triangle-small-down")])],1)]}}])},[_c('v-card',{attrs:{"width":"275","min-width":"200"}},[_c('v-row',{attrs:{"justify":"center","no-gutters":""}},[_c('v-col',{staticClass:"d-flex justify-center align-center background px-3",attrs:{"cols":"12"}},[_c('v-list-item',{attrs:{"two-line":""}},[_c('v-list-item-avatar',[(_vm.user.avatar)?_c('v-img',{attrs:{"src":_vm.isValidHttpUrl(_vm.user.avatar)
                            ? _vm.user.avatar
                            : _vm.constants.STORAGE_URL + _vm.user.avatar}}):_c('p',{staticClass:"ma-0"},[_vm._v("No Image")])],1),_c('v-list-item-content',[_c('p',{staticClass:"ma-0 text-body-2 font-weight-bold primary--text"},[_vm._v(" "+_vm._s(_vm.user.name)+" ")]),_c('p',{staticClass:"ma-0 text-caption primaryfont--text"},[_vm._v(" "+_vm._s(_vm.user.email)+" ")])])],1)],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-divider')],1),_c('v-col',{staticClass:"px-3",attrs:{"cols":"12"}},[_c('v-list',{attrs:{"dense":"","nav":""}},_vm._l((_vm.items),function(item,i){return _c('v-btn',{key:i,attrs:{"depressed":"","plain":""},on:{"click":function($event){return _vm.$router.push(item.link)}}},[_c('v-icon',{attrs:{"small":""},domProps:{"textContent":_vm._s(item.icon)}}),_c('p',{staticClass:"ml-4 ma-0"},[_vm._v(_vm._s(item.text))])],1)}),1)],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-divider')],1),_c('v-col',{staticClass:"px-3",attrs:{"cols":"12"}},[_c('v-list',{attrs:{"dense":"","nav":""}},[_c('v-list-item',{attrs:{"link":""},on:{"click":_vm.Logout}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-logout")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("تسجيل الخروج")])],1)],1)],1)],1)],1)],1)],1)],1)],1)]),_c('v-divider'),_c('v-list',{attrs:{"dense":"","nav":""}},_vm._l((_vm.links),function([text, link, icon, isGroup, items]){return _c('div',{key:text,staticClass:"pt-4 px-2"},[(!isGroup)?_c('v-list-item',{attrs:{"link":"","to":link,"color":"white","active-class":"primary"}},[_c('v-list-item-icon',[_c('v-icon',{staticClass:"ml-2",attrs:{"color":_vm.currentRouteName.includes(link) ? 'white' : 'grey_blue'},domProps:{"textContent":_vm._s(icon)}})],1),_c('v-list-item-content',[_c('v-list-item-title',{class:{
                'white--text': _vm.currentRouteName.includes(link),
                'grey_blue--text': !_vm.currentRouteName.includes(link),
              }},[_vm._v(_vm._s(text))])],1)],1):_vm._e(),(isGroup)?_c('v-list-group',{attrs:{"prepend-icon":icon,"no-action":""},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(text)}})],1)]},proxy:true}],null,true)},_vm._l((items),function([text, link]){return _c('v-list-item',{key:link,attrs:{"to":link,"color":"white","active-class":"primary"}},[_c('v-list-item-content',[_c('v-list-item-title',{class:{
                  'white--text': _vm.currentRouteName == link,
                  'grey_blue--text': _vm.currentRouteName != link,
                },domProps:{"textContent":_vm._s(text)}})],1)],1)}),1):_vm._e()],1)}),0)],1),_c('v-app-bar',{attrs:{"app":"","outlined":"","color":"primary","flat":"","dark":"","height":"64"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }